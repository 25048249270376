<template>
    <div class="">
        <div class="w-full pt-2">
            <div class="flex justify-center pt-10">
                <carousel :per-page="1" :loop="true" :autoplay="true"
                          class="w-4/5 md:w-1/2 max-h-full flex justify-center">
                    <slide class="flex justify-center">
                        <img width="400" height="400" src="../../assets/Image1.jpg" alt="" class="">
                    </slide>
                    <slide class="flex justify-center">
                        <img width="400" height="400" src="../../assets/Image2.jpg" alt=""
                             class="">
                    </slide>
                    <slide class="flex items-center">
                        <img src="../../assets/Image3.png" alt="" class="">
                    </slide>
                </carousel>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "ImageSlider"
    }
</script>

<style scoped>
</style>
