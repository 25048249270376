<template>
    <div class="pb-10">
        <div v-if="isMain && !isLounge" class="border-t-2 border-b-2 border-gray-600 py-4 px-1">
            <div class="text-3xl">
                MAIN BAR
            </div>
            <div class="flex justify-center text-2xl tracking-widest">
                Open Today
            </div>
        </div>
        <div v-if="isLounge && !isMain" class="border-t-2 border-b-2 border-gray-600 py-4 px-1">
            <div class="text-3xl">
                LOUNGE BAR
            </div>
            <div class="flex justify-center text-2xl tracking-widest">
                Open Today
            </div>
        </div>
        <div v-if="isMain && isLounge" class="border-t-2 border-b-2 border-gray-600 py-4 px-1">
            <div class="text-3xl">
                MAIN BAR
                <span class="tracking-normal font-normal">
                    AND
                </span>
                LOUNGE BAR
            </div>
            <div class="flex justify-center text-2xl tracking-widest">
                Open Today
            </div>
        </div>
    </div>
</template>

<script>
    import firebase from "firebase/app";

    export default {
        name: "OpenBox",
        data() {
            return {
                db: firebase.firestore(),
                bars: [],
                isMain: false,
                isLounge: false,
            }
        },
        mounted() {
            this.db.collection("openbars").get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    switch (doc.data().name) {
                        case "lounge":
                            this.isLounge = doc.data().isOpen;
                            break;
                        case "main":
                            this.isMain = doc.data().isOpen;
                            break;
                    }
                    this.bars.push(doc.data())
                });
            })
                .catch(() => {
                    this.showError = true;
                });
        },
        methods: {},
    }
</script>

<style scoped>

</style>
