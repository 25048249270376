<template>
    <div class="h-full">
        <div class="flex justify-center pt-16">
            <Menu></Menu>
        </div>
        <div class="flex justify-center pt-16">
            <div class="w-72">
                <div class="flex justify-center text-3xl bg-gray-600 text-gray-50">
                    EVENTS
                </div>
            </div>
        </div>
        <image-slider></image-slider>

        <div class="flex italic justify-center pt-24 text-xl">
            Do you have a special date coming up?
        </div>
        <div class="flex justify-center text-lg px-10 md:px-0 text-center">
            We do Birthdays, Weddings, Celebrations and Parties
        </div>
        <div class="flex justify-center pt-10 hover:text-black">
            <contact :hover-black="true"></contact>
        </div>
        <div class="pt-10 pb-24">
            <signup-form></signup-form>
        </div>

    </div>
</template>

<script>
    import Menu from "./Menu";
    import ImageSlider from "./ImageSlider";
    import Contact from "./Contact";
    import SignupForm from "./SignupForm";
    export default {
        name: "SecondaryPage",
        components: {SignupForm, Contact, ImageSlider, Menu}
    }
</script>

<style scoped>

</style>
