<template>
    <div class="pr-2 sm:inset-auto flex justify-center md:flex-none md:justify-start">
        <a href="tel:09-281-3880" class="mr-5 md:mr-2">
            <div v-if="hoverBlack">
                <button class="p-1 text-gray-400 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span class="sr-only inline-block">Phone Number</span>
                    <!-- phone -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block  md:mr-0" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                    </svg>
                    <span class="md:pl-2">09 281 3880</span>
                </button>
            </div>
            <div v-else>
                <button class="p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span class="sr-only inline-block">Phone Number</span>
                    <!-- phone -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block  md:mr-0" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                    </svg>
                    <span class="md:pl-2">09 281 3880</span>
                </button>
            </div>

        </a>


        <a href="mailto:events@theupstairsbar.co.nz">
            <div v-if="hoverBlack">
                <button class="p-1 text-gray-400 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span class="sr-only inline-block">Phone Number</span>
                    <!-- mail -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <span class="md:pl-2">Email</span>
                </button>
            </div>
            <div v-else>
                <button class="p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span class="sr-only inline-block">Phone Number</span>
                    <!-- mail -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <span class="md:pl-2">Email</span>
                </button>
            </div>

        </a>

    </div>












</template>

<script>
    export default {
        name: "Contact",
        props: {
            hoverBlack: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style scoped>

</style>
