<template>
    <div class="bg-gray-700 text-gray-400 h-66 p-5 ">
        <div class="sm:flex sm:justify-around md:flex md:justify-around">
            <div class="flex justify-center md:flex-none md:justify-start hover:text-white">
                <Location></Location>
            </div>
            <div class="flex justify-center md:flex-none md:justify-start">
                <opening-hours></opening-hours>
            </div>
            <div class="flex justify-center md:flex-none md:justify-start">
                <contact></contact>
                <span class="static sm:absolute flex sm:ml-10 sm:mt-14 bg-gray-700 px-auto">
                    <a href="https://www.facebook.com/TheUpstairsBarNZ/" class="hover:text-white pl-2 pr-4">
                        <div class="justify-center flex">
                            <img height="30" width="30" src="../../assets/FLogo.webp" alt="Facebook">
                        </div>
                        <div>
                            Facebook
                        </div>
                    </a>
                    <a href="https://www.instagram.com/theupstairsbar_nz/" class="hover:text-white">
                        <div class="justify-center flex">
                            <img height="30" width="30" src="../../assets/Instagram.webp" alt="Instagram">
                        </div>
                        <div>
                            Instagram
                        </div>
                    </a>
                </span>

            </div>
        </div>
    </div>
</template>

<script>
    import Location from "./Location";
    import OpeningHours from "./OpeningHours";
    import Contact from "./Contact";
    export default {
        name: "Footer",
        components: {Contact, OpeningHours, Location}
    }
</script>

<style scoped>

</style>
