<template>
    <div class="min-w-screen h-screen  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none">
        <div class="absolute bg-black opacity-80 inset-0 z-0"></div>
        <div class="w-4/5  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
            <!--content-->
            <div class="">
                <!--body-->
                <div class="text-center p-5 flex-auto justify-center">
                    <h3 class="text-xl font-bold py-4 ">Sign out without saving?</h3>
                    <p class="text-sm text-gray-500 px-8"></p>
                </div>
                <!--footer-->
                <div class="p-3 mt-2 text-center space-x-10 md:flex">
                    <button class=" md:block mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-200"
                        v-on:click="closeModal(0)"
                    >
                        Back
                    </button>
                    <button class=" md:block mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600"
                            v-on:click="closeModal(1)"

                    >
                        Sign Out
                    </button>
                    <button class=" md:block mb-2 md:mb-0 bg-green-500 border border-green-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-green-600"
                            v-on:click="closeModal(2)"
                    >
                        Save & Sign Out
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ConfirmModal",

        methods: {
            closeModal(mode) {
                this.$emit("close", mode);
            }
        },
    }
</script>

<style scoped>

</style>
