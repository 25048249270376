<template>
    <div class=" text-center hover:underline">
        <a href="https://www.google.co.nz/maps/place/Level+2%2F180+Gulf+Harbour+Drive,+Gulf+Harbour,+Whangapar%C4%81oa+0930/@-36.6172017,174.7952002,17z/data=!3m1!4b1!4m5!3m4!1s0x6d0d2f0dadeaaaab:0x7068543bde493e3b!8m2!3d-36.617206!4d174.7973889">
            <div>
                Level One, 180 Gulf Harbour Dr
            </div>
            <div class="">Gulf Harbour</div>
        </a>
    </div>
</template>

<script>
    export default {
        name: "Location"
    }
</script>

<style scoped>

</style>
