<template>
    <div class="bg-gray-200 h-full">
        <div class="h-screen">
            <picker></picker>

        </div>
    </div>
</template>

<script>
    import firebase from 'firebase/app'
    import Picker from "./components/Dashboard/Picker";
    export default {
        name: "Dashboard",
        components: { Picker},
        mounted() {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    //stay on page
                } else {
                    this.$router.push("/").catch(err => {
                        // Ignore the vuex err regarding  navigating to the page they are already on.
                        if (
                            err.name !== 'NavigationDuplicated' &&
                            !err.message.includes('Avoided redundant navigation to current location')
                        ) {
                            // But print any other errors to the console
                            console.warn(err);
                        }
                    });
                }
            });
        },

        methods: {

        },
    }
</script>

<style scoped>

</style>
