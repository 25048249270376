<template>
    <div class="flex justify-center items-center h-screen bg-gray-200">
        <AdminLogin class="content-center"></AdminLogin>
    </div>
</template>

<script>
    import AdminLogin from "./components/Login/AdminLogin";
    export default {
        name: "Admin",
        components: {AdminLogin}
    }
</script>

<style scoped>

</style>
