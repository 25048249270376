<template>
    <div class="w-72 p-2">
        <div class="flex justify-center text-3xl bg-gray-600 text-gray-50" v-if="hasTitle">HOURS</div>
        <div class="pt-4 px-8 flex justify-between">
            <span class="font-extrabold">Mon</span>
            <span>Closed</span>
        </div>
        <div class="px-8 flex justify-between">
            <span class="font-extrabold">Tue</span>
            <span>Closed</span>
        </div>
        <div class="px-8 flex justify-between">
            <span class="font-extrabold">Wed</span>
            <span>12pm - 8pm</span>
        </div>
        <div class="px-8 flex justify-between">
            <span class="font-extrabold">Thu</span>
            <span>12pm - 8pm</span>
        </div>
        <div class="px-8 flex justify-between">
            <span class="font-extrabold">Fri</span>
            <span>12pm - Late</span>
        </div>
        <div class="px-8 flex justify-between">
            <span class="font-extrabold">Sat</span>
            <span>12pm - Late</span>
        </div>
        <div class="px-8 flex justify-between">
            <span class="font-extrabold">Sun</span>
            <span>12pm - 8pm</span>
        </div>

    </div>
</template>

<script>
    export default {
        name: "OpeningHours",
        props: {
            hasTitle: {
                type: Boolean,
                default: false
            },
        },
    }
</script>

<style scoped>

</style>
