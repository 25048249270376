<template>
    <div class="shadow-xl bg-white w-80 h-66 rounded-2xl p-4">
        <div class="flex justify-center">
            <div class="">
                <div class="flex justify-center">
                    <label for="email"><b>Email</b></label>
                </div>
                <div class="pt-2 border-b-2 border-gray-400" v-on:click="setInputInvalid">
                    <input type="email" placeholder="Enter Email" id="email" v-model="email" required>
                </div>
            </div>
        </div>
        <div class="flex justify-center">
            <div>
                <div class="flex justify-center pt-4">
                    <label for="password"><b>Password</b></label>
                </div>
                <div class="pt-2 border-b-2 border-gray-400" v-on:click="setInputInvalid">
                    <input type="password" placeholder="Password" id="password" v-model="password" required>
                </div>
            </div>

        </div>
        <div class="text-red-400 pt-2 flex justify-center" v-if="inputValid">
            Email or Password was incorrect.
        </div>
        <div class="flex justify-center pt-4">
            <button class="bg-green-500 hover:bg-green-600 text-white rounded-full py-2 px-5" type="submit"
                v-on:click="submitLogin"
            >Submit</button>
        </div>
    </div>


</template>

<script>
    import firebase from 'firebase/app'
    export default {
        name: "AdminLogin",

        data() {
            return {
                email: "",
                password: "",
                inputValid: null,
            }
        },
        mounted() {
            firebase.auth().signOut()
        },
        methods: {
            submitLogin() {
                firebase.auth().signInWithEmailAndPassword(this.email, this.password)
                    .then(() => {
                    // Signed in
                    this.$router.push("/adm-dashboard");
                })
                    .catch(() => {
                        this.inputValid = true;
                    });
            },

            setInputInvalid() {
                this.inputValid = false
            }
        },
    }
</script>

<style scoped>

</style>
