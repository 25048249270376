<template>
    <div class="w-72">
        <div class="flex justify-center text-3xl bg-gray-600 text-gray-50">
            MENU
        </div>
        <div class="pt-2">
            <div class="flex justify-center py-2  tracking-wide hover:underline hover:text-gray-700">
                <a href="/Price List - Small Plates.pdf" download>SMALL PLATES</a>
            </div>
            <div class="flex justify-center py-2  tracking-wide hover:underline hover:text-gray-700">
                <a href="/Price List - Pizzas.pdf" download>PIZZAS</a>
            </div>
            <div class="flex justify-center py-2  tracking-wide hover:underline hover:text-gray-700">
                <a href="/Price List - Platters.pdf" download>PLATTERS</a>
            </div>
            <div class="flex justify-center py-2 tracking-wide hover:underline hover:text-gray-700">
                <a href="/Price List - Drinks.pdf" download>DRINKS</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Menu"
    }
</script>

<style scoped>

</style>
