<template>
    <div>
        <Navbar></Navbar>
        <LandingPage class=""></LandingPage>
        <div class="">
            <SecondaryPage></SecondaryPage>
            <Footer></Footer>
        </div>

    </div>
</template>

<script>
    import firebase from 'firebase/app'
    import SecondaryPage from "./components/Home/SecondaryPage";
    import LandingPage from "./components/Home/LandingPage";
    import Navbar from "./components/Home/Navbar";
    import Footer from "./components/Home/Footer";

    export default {
        name: "Home",
        components: {
            SecondaryPage,
            LandingPage,
            Navbar,
            Footer
        },
        mounted() {
            firebase.auth().signOut()
        }
    }
</script>

<style scoped>

</style>
