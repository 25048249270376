<template>
    <div class="h-full">
        <div class="flex justify-center pt-12">
            <div class="w-full sm:w-2/3 md:w-1/3">
                <img src="../../assets/TheUpstairsBar.webp" alt="The Upstairs Bar">
            </div>
        </div>
        <div class="">
            <div class="flex justify-center">
                <Location></Location>
            </div>
        </div>
        <div class="flex justify-center pt-6">
            <div class="text-xl">
                Open to the Public & Members
            </div>
        </div>
        <div class="px-14 md:px-0 pt-2 italic flex justify-center text-center">
            You don't have to be a member of Gulf Harbour CC
        </div>
        <div class="italic flex justify-center">
            Come upstairs and enjoy the bar!
        </div>
        <div class="pt-10 flex justify-center hover:text-black">
            <contact :hover-black="true"></contact>
        </div>
        <div class="px-20 md:px-0 pt-10 flex justify-center">
            <OpenBox></OpenBox>
        </div>
        <div class="pt-3 flex justify-center">
            <OpeningHours :has-title="true"></OpeningHours>
        </div>
    </div>
</template>

<script>
    import OpeningHours from "./OpeningHours";
    import OpenBox from "./OpenBox";
    import Location from "./Location";
    import Contact from "./Contact";

    export default {
        name: "LandingPage",
        components: {Contact, OpenBox, Location, OpeningHours}
    }
</script>

<style scoped>

</style>
