import Vue from 'vue'
import App from './App.vue'
import Home from './Home.vue'
import Admin from './Admin.vue'
import Dashboard from "./Dashboard";

import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);


import firebase from "firebase/app";
require('firebase/firestore');
require('firebase/auth');

import VueRouter from 'vue-router'

Vue.config.productionTip = false;

firebase.initializeApp({
  apiKey: "AIzaSyDKMtlC7L9LSTOyvZVIf-tcjZsrblxTcnI",
  authDomain: "upstairsbar.firebaseapp.com",
  projectId: "upstairsbar",
  storageBucket: "upstairsbar.appspot.com",
  messagingSenderId: "383186832400",
  appId: "1:383186832400:web:f2280b6fc9e49c8e100e95"
});

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home },
  { path: '/admin', component: Admin },
  {path: '/adm-dashboard', component: Dashboard}
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  routes, // short for `routes: routes`
  mode: "history"
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
