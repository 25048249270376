<template>
    <div class="">
        <div class="bg-gray-200 h-full">
            <nav class="bg-gray-50 w-full fixed z-10">
                <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                    <div class="relative flex items-center justify-between h-12">
                        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
                            <!-- Mobile menu button-->
                        </div>
                        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <div class="flex-shrink-0 flex items-center">
                                <!--main-->
                                <img class="hidden lg:block h-8 w-auto" src="../../assets/logo.png"
                                     alt="The Upstairs Bar">
                            </div>
                            <div class="hidden sm:block sm:ml-6">
                                <div class="flex space-x-4">
                                </div>
                            </div>
                        </div>
                        <div class="absolute inset-y-0 right-0 flex items-center pr-40 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <div v-if="changesMade">
                                <button class="bg-green-500 text-white px-4 py-1 rounded-full hover:bg-green-600"
                                        v-on:click="save">Save Changes
                                </button>
                            </div>

                        </div>
                        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <button class="bg-gray-700 text-white px-4 py-1 rounded-full hover:bg-red-500"
                                    v-on:click="signOut">Sign Out
                            </button>
                        </div>
                    </div>
                </div>

            </nav>

            <!--Begin Content-->
            <div class="pt-20">
                <div v-if="showModal">
                    <confirm-modal @close="closeModal"></confirm-modal>
                </div>

                <div v-if="showError" class="text-red-200">
                    An error has occurred when retrieving bars. Please reload the page.
                </div>


                <div v-for="bar in this.bars" v-bind:key="bar.name" class="flex justify-center mb-10">
                    <div class="shadow-xl w-60 h-16 rounded-lg bg-white flex justify-between px-7 items-center">
                        {{bar.name}}
                        <span>
                    <span v-if="bar.isOpen">
                        <button class="bg-green-500 px-4 text-white px-4 py-1 rounded-full hover:bg-green-600"
                            v-on:click="toggleIsOpen(bar)"
                        >Open</button>
                    </span>
                    <span v-else>
                        <button class="bg-red-500 px-4 text-white px-4 py-1 rounded-full hover:bg-red-600"
                            v-on:click="toggleIsOpen(bar)">Closed</button>
                    </span>
                </span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import firebase from 'firebase/app'
    import ConfirmModal from "./ConfirmModal";

    export default {
        name: "Picker",
        components: {ConfirmModal},
        data() {
            return {
                db: firebase.firestore(),
                bars: [],
                changesMade: false,
                showModal: false,
                showError: false
            }
        },
        mounted() {
            this.db.collection("openbars").get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    this.bars.push(doc.data())
                });
            })
                .catch(() => {
                    this.showError = true;
                });
        },
        methods: {
            firebaseSignOut() {
                firebase.auth().signOut()
            },

            signOut() {
                if (this.changesMade) {
                    this.showModal = true;
                } else {
                    this.firebaseSignOut()
                }

            },
            save() {
                for (let i in this.bars) {
                    let bar = this.bars[i];
                    this.db.collection("openbars").doc(bar.name).set({
                        name: bar.name,
                        isOpen: bar.isOpen
                    }).then(() => {
                        this.changesMade = false;
                    })
                }
            },
            toggleIsOpen(bar) {
                this.changesMade = true;
                bar.isOpen = !bar.isOpen
            },

            /**
             *
             * @param mode
             *  0: Close and do nothing
             *  1: Exit
             *  2: Save and Exit
             */
            closeModal(mode) {
                this.showModal = false;
                switch (mode) {
                    case 1:
                        this.firebaseSignOut();
                        break;
                    case 2:
                        this.save();
                        this.firebaseSignOut();
                        break;

                }
            }
        },
    }
</script>

<style scoped>

</style>
